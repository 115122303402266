export function applyFilter(array, filter) {
  return array.filter((obj) =>
    Object.entries(filter).every(([prop, find]) => find.includes(obj[prop]))
  );
}

export function groupByField(array, field) {
  return array
    .reduce((accumulator, current) => {
      if (current[field] && !accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, [])
    .sort();
}

export function isStringIsInArray(array, stringContent) {
  if (array && array.length > 0 && stringContent) {
    return array.some((match) => stringContent.includes(match));
  } else {
    return false;
  }
}
