import { getFatigueStatus } from "../helpers/fatigueColorHelper";

export function mapActivityToLocal(activity) {
  if (activity) {
    const { status } = getFatigueStatus(activity.fatigueScore, true);
    return {
      ...activity,
      fatigueRisk: status,
    };
  } else {
    return null;
  }
}

export function mapActivitiesToLocal(activities) {
  if (activities) {
    return activities.map((activity) => {
      return mapActivityToLocal(activity);
    });
  } else {
    return [];
  }
}
